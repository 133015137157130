@import "../../styles/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 250px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 5px $color-primary;
  font-size: 20px;

  .nameWrapper {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    .name {
      color: $color-primary;
    }
  }

  .newNameWrapper {
    font-size: 16px;
  }
}