$color-primary: #29854d;
$color-primary-text: #ffffff;
$color-secondary: #ffd265;
$color-secondary-text: #7e621d;

$color-notification-background: #ffffff;
$color-notification-success: #60A917;
$color-notification-error: #CE352C;
$color-notification-warning: #FF984E;
$color-notification-information: #1BA1E2;
